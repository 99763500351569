import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { BrowserRouter } from "react-router-dom";
import api from "./services/api";
import { QueryClientProvider } from "react-query";
import queryClient from "./services/queryClient";
import {
  AUTH_TOKEN,
  getBrandName,
  getBrandProductFlowConfig,
  getRouterPath,
  PARTNER_NAME,
  PARTNER_PRODUCT,
} from "./utils";
import { BrandName, ProductName } from "./shared/types";
import ContextProvider, { Context } from "./shared/components/ContextProvider";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import configJSON from "../package.json";

const routerPath = getRouterPath();

const DynamicRouter = lazy(() => import(`${routerPath}`));

const Router = () => {
  const { context } = useContext(Context);
  const authToken = context[AUTH_TOKEN];
  const partnerName = context[PARTNER_NAME] as BrandName;
  const productName = context[PARTNER_PRODUCT] as ProductName;
  const [isApiReady, setIsApiReady] = useState(false);

  const setupApi = useCallback(() => {
    const partner = partnerName || getBrandName();
    const { PRODUCT } = getBrandProductFlowConfig();

    // Use product name from context or default
    const product = productName || PRODUCT;

    // Setup api partner and product names in headers
    api.setupAdditionalHeaders({
      PartnerName: partner,
      ProductName: product,
      token: authToken,
    });
    api.setupHosts(partner);
    setIsApiReady(true);
  }, [authToken, partnerName, productName]);

  useEffect(() => {
    setupApi();
  }, [setupApi]);

  return isApiReady ? (
    <BrowserRouter>
      <DynamicRouter />
    </BrowserRouter>
  ) : null;
};

const App = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<div>Snart ferdig...</div>}>
        <QueryClientProvider client={queryClient}>
          <ContextProvider>
            <Router />
            <p hidden>{`app v${configJSON.version}`}</p>
          </ContextProvider>
        </QueryClientProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
