import Sentry from "./sentry.service";
import { getBrandName } from "../utils";

export const getSentryPerformanceTransaction = (name: string) => {
  const brandName = getBrandName();
  const transaction = Sentry.startTransaction({
    name: `${name} [${brandName}]`,
  });
  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));

  return transaction;
};
