import config from "../../package.json";
import { ExtendedError } from "../shared/types";
import Sentry from "./sentry.service";
import { getBrandName } from "../utils";

const errorLoggerService = {
  captureMessage: (message: string, extra: Record<string, unknown>) => {
    Sentry.withScope((scope) => {
      scope.setTag("partner", getBrandName());
      scope.setTag("app:version", config.version);

      scope.setExtras(extra);
      if (extra?.integration) {
        scope.setTag("integration", extra.integration as string);
      }

      Sentry.captureMessage(message, scope);
    });
  },
  captureException: (
    exception: ExtendedError,
    extra: Record<string, unknown>
  ) => {
    Sentry.withScope((scope) => {
      scope.setTag("partner", getBrandName());
      scope.setTag("app:version", config.version);

      if (extra?.integration) {
        scope.setTag("integration", extra.integration as string);
      }

      scope.setTag(
        "error-status",
        exception.status || exception.response?.status || "unknown"
      );

      scope.setTag(
        "error-type",
        exception.statusText || exception.response?.statusText || "unknown"
      );

      scope.setExtras({
        ...extra,
        message: exception.message || "NetworkError",
        level: "error",
        response: exception.response,
      });

      Sentry.captureException(exception, scope);
    });
  },
};

export default errorLoggerService;
