import React, { useCallback, useState } from "react";
import { PARTNER_CONFIG } from "../../utils";
import { ConfigType } from "../types";

export const CONTEXT_CACHE_KEY = "CONTEXT_CACHE";

const initialContextStateString = localStorage.getItem(CONTEXT_CACHE_KEY);
const initialContextState = initialContextStateString
  ? JSON.parse(initialContextStateString)
  : {};

type ContextType = {
  context: {
    // @ts-ignore
    [PARTNER_CONFIG]: ConfigType;
    [key: string]: string;
  };
  setContext: (newContextState: React.SetStateAction<{}>) => void;
  clearContext: () => void;
};

export const Context = React.createContext<ContextType>({
  context: initialContextState,
  setContext: () => {},
  clearContext: () => {},
});

const ContextProvider = ({
  children,
  testInitialContextState,
}: {
  children: React.ReactNode;
  testInitialContextState?: Record<string, unknown>;
}) => {
  const [context, setContextState] = useState(
    testInitialContextState || initialContextState
  );

  const setContext = useCallback(
    (newContextState: React.SetStateAction<{}>) => {
      setContextState((prevState: Record<string, unknown>) => {
        const newState = { ...prevState, ...newContextState };
        localStorage.setItem(CONTEXT_CACHE_KEY, JSON.stringify(newState));

        return newState;
      });
    },
    []
  );

  const clearContext = useCallback(() => {
    // Preserve PARTNER_CONFIG
    setContextState({ [PARTNER_CONFIG]: context[PARTNER_CONFIG] });
    localStorage.removeItem(CONTEXT_CACHE_KEY);
  }, [context]);

  return (
    <Context.Provider value={{ context, setContext, clearContext }}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
