import React, { FC } from "react";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        paddingTop: 100,
        margin: "auto 24px",
      },
    },
    contentWrapper: {
      [theme.breakpoints.up("xs")]: {
        maxWidth: "90%",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 980,
      },
    },
  })
);

type PageContentType = {
  children: React.ReactNode;
};

const PageContent: FC<PageContentType> = ({ children }) => {
  const { content, contentWrapper } = useStyles();
  return (
    <section className={content}>
      <div className={contentWrapper}>{children}</div>
    </section>
  );
};

export default PageContent;
