import React, { ErrorInfo, ReactNode } from "react";
import { getErrorType } from "../../utils";
import { ERROR_TYPES } from "../types";
import ErrorPage from "../pages/ErrorPage";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorType?: ERROR_TYPES;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    const errorType = getErrorType(error);
    return { hasError: true, error, errorType };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, "errorInfo:", errorInfo);
  }

  public render() {
    const { error, hasError, errorType } = this.state;
    if (hasError && error && errorType) {
      return <ErrorPage error={error} errorType={errorType} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
