import React, { FC, useEffect } from "react";
import "../../App.css";
import { cleanupStorage } from "../../utils";
import { ERROR_TYPES } from "../types";
import PageContent from "../components/PageContent";

type ErrorPageType = FC<{ error?: Error; errorType: ERROR_TYPES }>;

export const ErrorPage: ErrorPageType = ({ error, errorType }) => {
  const errorMessageMap = {
    [ERROR_TYPES.general]: {
      title: "Error",
      subtitle: error?.message,
      description: "Something wrong happened...",
    },
    [ERROR_TYPES.missingModule]: {
      title: "Routing Error",
      subtitle: error?.message,
      description: "The routing was reset. Reload the page",
    },
    [ERROR_TYPES.notFound]: {
      title: "Error 404",
      subtitle: null,
      description: "Page Not Found",
    },
  };

  useEffect(() => {
    // Cleanup storage in case of missing module error (lazy loading missing file error)
    if (errorType === ERROR_TYPES.missingModule) {
      cleanupStorage();
    }
  }, [errorType]);

  const { title, subtitle, description } = errorMessageMap[errorType];

  return (
    <PageContent>
      <h1>{title}:</h1>
      {subtitle ? <pre>{subtitle}</pre> : null}
      {description ? <pre>{description}</pre> : null}
    </PageContent>
  );
};

export default ErrorPage;
