import { getPartnerHost } from "../utils";
import { BrandName, ExtendedError, ProductName } from "../shared/types";
import { getSentryPerformanceTransaction } from "./performanceLogger.service";
import errorLoggerService from "./errorLogger.service";

const getSentryPerformanceTransactionAndSpan = (
  url: string,
  description?: string
) => {
  const transaction = getSentryPerformanceTransaction("api");
  const span = transaction.startChild({
    op: url,
    description,
  });

  return { transaction, span };
};

type AgentLoginFormDataType = {
  username: string;
  password: string;
};

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const getExtendedError = async (resp: Response) => {
  let response: Record<string, unknown>;

  try {
    // Get response text first
    const responseText = await resp.text();

    // Try to parse the text as JSON
    response = JSON.parse(responseText);
  } catch (parseError) {
    // If parsing as JSON fails, use the response text directly
    response = {
      message: (parseError as Error).message,
      status: resp.status,
      statusText: resp.statusText,
    };
  }

  // Get error from response
  const errorBody = response.error as Record<string, unknown>;
  const errorMessage = response.error
    ? `HTTP Error: ${errorBody.status || errorBody.code || resp.status} ${
        errorBody.message
      }`
    : `HTTP Error: ${resp.status} ${resp.statusText}`;

  // Expose ApiV2Error with additional information
  return new ExtendedError(
    errorMessage,
    resp.status,
    resp.statusText,
    response
  );
};

const api = {
  additionalHeaders: {
    PartnerName: "",
    ProductName: "",
    token: "",
  },
  apiUri: "",
  apiV2Uri: "",
  apiIsV2Uri: "",
  setupHosts(partnerName: BrandName) {
    const partnerHostName = getPartnerHost(partnerName);

    this.apiUri = `${partnerHostName}api/partner/graphql`;
    this.apiV2Uri = `${partnerHostName}api/v2`;
    this.apiIsV2Uri = `${partnerHostName}api/is/v2`;
  },
  setupAdditionalHeaders(additionalHeaders: {
    PartnerName?: BrandName;
    ProductName?: ProductName;
    token?: string;
  }) {
    this.additionalHeaders = {
      ...this.additionalHeaders,
      ...additionalHeaders,
    };
  },
  async agentLogin(agentLoginFormData: AgentLoginFormDataType) {
    const body = JSON.stringify(agentLoginFormData);
    const url = `${this.apiIsV2Uri}/agent/login`;
    const { span, transaction } = getSentryPerformanceTransactionAndSpan(url);
    try {
      const response = await fetch(url, {
        method: "POST",
        body,
        headers: {
          ...defaultHeaders,
          ...this.additionalHeaders,
        },
      });

      if (!response.ok) {
        throw await getExtendedError(response);
      }

      span.setStatus("ok");

      return await response.json();
    } catch (error) {
      const extendedError = error as ExtendedError;

      span.setStatus(extendedError.status.toString());
      errorLoggerService.captureException(extendedError, {
        integration: "bff:agent",
        url,
      });

      console.error(error);
      throw error;
    } finally {
      span.finish();
      transaction.finish();
    }
  },
  async getAgentSession(sessionId: string) {
    const url = `${this.apiIsV2Uri}/agent/token`;
    const { span, transaction } = getSentryPerformanceTransactionAndSpan(url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          ...defaultHeaders,
          ...this.additionalHeaders,
          "session-id": sessionId,
        },
      });

      if (!response.ok) {
        throw await getExtendedError(response);
      }

      span.setStatus("ok");

      return await response.json();
    } catch (error) {
      const extendedError = error as ExtendedError;

      span.setStatus(extendedError.status.toString());
      errorLoggerService.captureException(extendedError, {
        integration: "bff:agent",
        url,
      });

      console.error(error);
      throw error;
    } finally {
      span.finish();
      transaction.finish();
    }
  },
  async validateAgentSession(sessionId: string) {
    const url = `${this.apiIsV2Uri}/agent/validate-session`;
    const { span, transaction } = getSentryPerformanceTransactionAndSpan(url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          ...defaultHeaders,
          ...this.additionalHeaders,
          "session-id": sessionId,
        },
      });

      if (!response.ok) {
        throw await getExtendedError(response);
      }

      span.setStatus("ok");

      return await response.json();
    } catch (error) {
      const extendedError = error as ExtendedError;

      span.setStatus(extendedError.status.toString());
      errorLoggerService.captureException(extendedError, {
        integration: "bff:agent",
        url,
      });

      console.error(error);
      throw error;
    } finally {
      span.finish();
      transaction.finish();
    }
  },
  async createAgentSession() {
    const url = `${this.apiIsV2Uri}/agent/create-session`;
    const { span, transaction } = getSentryPerformanceTransactionAndSpan(url);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          ...defaultHeaders,
          ...this.additionalHeaders,
        },
      });

      if (!response.ok) {
        throw await getExtendedError(response);
      }

      span.setStatus("ok");

      return await response.json();
    } catch (error) {
      const extendedError = error as ExtendedError;

      span.setStatus(extendedError.status.toString());
      errorLoggerService.captureException(extendedError, {
        integration: "bff:agent",
        url,
      });

      console.error(error);
      throw error;
    } finally {
      span.finish();
      transaction.finish();
    }
  },
  async logoutAgent(sessionId: string) {
    const url = `${this.apiIsV2Uri}/agent/logout`;
    const { span, transaction } = getSentryPerformanceTransactionAndSpan(url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          ...defaultHeaders,
          ...this.additionalHeaders,
          "session-id": sessionId,
        },
      });

      if (!response.ok) {
        throw await getExtendedError(response);
      }

      span.setStatus("ok");

      return await response.json();
    } catch (error) {
      const extendedError = error as ExtendedError;

      span.setStatus(extendedError.status.toString());
      errorLoggerService.captureException(extendedError, {
        integration: "bff:agent",
        url,
      });

      console.error(error);
      throw error;
    } finally {
      span.finish();
      transaction.finish();
    }
  },
  async getBankIdAuth(brand: BrandName) {
    const url = `${this.apiIsV2Uri}/auth/bankID`;
    const { span, transaction } = getSentryPerformanceTransactionAndSpan(url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          ...defaultHeaders,
          ...this.additionalHeaders,
          PartnerName: brand,
        },
      });

      if (!response.ok) {
        throw await getExtendedError(response);
      }

      span.setStatus("ok");

      return await response.json();
    } catch (error) {
      const extendedError = error as ExtendedError;

      span.setStatus(extendedError.status.toString());
      errorLoggerService.captureException(extendedError, {
        integration: "bff:bankID",
        url,
      });

      console.error(error);
      throw error;
    } finally {
      span.finish();
      transaction.finish();
    }
  },
};

export default api;
