import { getEnv } from "../utils";
import { EnvName } from "../shared/types";
import * as SentryTracing from "@sentry/tracing";
import * as Sentry from "@sentry/react";

const environment = getEnv();
const dsn =
  "https://37a2461ea03542bda1f1a85d860df61e@o1129041.ingest.sentry.io/6172544";
const debug = [EnvName.local, EnvName.dev].includes(environment);

// Use a 100% of events capturing/tracing for DEV and testing and 20% for PROD environment
// It is recommended lowering this value in production
const tracesSampleRate = environment === EnvName.prod ? 0.2 : 1.0;

// Tracing initialization
SentryTracing.addExtensionMethods();

// Sentry initialisation
Sentry.init({
  dsn,
  environment,
  debug,
  // To set a uniform sample rate
  tracesSampleRate,
});

export default Sentry;
