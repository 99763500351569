import {
  BrandDomainsMapType,
  BrandName,
  BrandProductFlowConfigType,
  BrandsMapType,
  EnvName,
  ERROR_TYPES,
  FlowName,
  GetThemeOptionsArgType,
  ProductName,
} from '../shared/types'

import partnerHosts from './partnerHosts.json'
import { Location } from 'react-router'
import { ThemeOptions } from '@material-ui/core'

export const AUTH_TOKEN = 'AUTH_TOKEN'
export const AGENT_SESSION_ID = 'AGENT_SESSION_ID'
export const AGENT_SESSION_TIMESTAMP = 'AGENT_SESSION_TIMESTAMP'
export const PARTNER_NAME = 'PARTNER_NAME'
export const PARTNER_PRODUCT = 'PARTNER_PRODUCT'
export const PARTNER_CONFIG = 'PARTNER_CONFIG'
export const AUTH_PARTNER_ENV = 'AUTH_PARTNER_ENV'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'
export const IS_LOADING = 'IS_LOADING'

export const cleanupStorage = () => {
  localStorage.removeItem('BRAND')
  // localStorage.removeItem("FLOW");
  // localStorage.removeItem("PRODUCT");
}

export const defaultBrandProductFlowMap: BrandsMapType = {
  [BrandName.audi]: {
    flow: FlowName.qb,
    product: ProductName.vehicle,
  },
  [BrandName.skoda]: {
    flow: FlowName.qb,
    product: ProductName.vehicle,
  },
  [BrandName.volkswagen]: {
    flow: FlowName.qb,
    product: ProductName.vehicle,
  },
  [BrandName.agentMMG]: {
    flow: FlowName.qb,
    product: ProductName.vehicle,
  },
  [BrandName.agentNord]: {
    flow: FlowName.qb,
    product: ProductName.vehicle,
  },
  [BrandName.authPortal]: {
    flow: FlowName.auth,
    product: ProductName.vehicle,
  },
  [BrandName.instabank]: {
    flow: FlowName.qb,
    product: ProductName.vehicle,
  },
  [BrandName.nord]: {
    flow: FlowName.qb,
    product: ProductName.vehicle,
  },
}

const brandDomainsMap: BrandDomainsMapType = {
  'auth.dev.dg-eleks.com': BrandName.authPortal,
  'auth.acc.dg-eleks.com': BrandName.authPortal,
  'auth.digisure.no': BrandName.authPortal,
  'agent-portal.dev.dg-eleks.com': BrandName.agentMMG,
  'agent-portal.acc.dg-eleks.com': BrandName.agentMMG,
  'moller.digisure.no': BrandName.agentMMG,
  'agent-nord.dev.dg-eleks.com': BrandName.agentNord,
  'agent-nord.acc.dg-eleks.com': BrandName.agentNord,
  'agent.nordforsikring.no': BrandName.agentNord,
}

export const getBrandName = () => {
  // Resolve brand name by domain name / hostname (highest priority)
  const brandNameByDomain = brandDomainsMap[window.location.hostname]

  // Resolve brand name by localStorage "BRAND" variable (for Debugging on localhost)
  const brandNameForDebug = localStorage.getItem('BRAND') as BrandName

  // Default brand name as a fallback (lowest priority)
  const brandNameDefault = BrandName.agentMMG

  return brandNameByDomain || brandNameForDebug || brandNameDefault
}

export const getBrandProductFlowConfig = (): BrandProductFlowConfigType => {
  const BRAND = getBrandName()
  const FLOW = defaultBrandProductFlowMap[BRAND].flow
  const PRODUCT = defaultBrandProductFlowMap[BRAND].product

  return {
    BRAND,
    FLOW,
    PRODUCT,
  }
}

export const getRouterPath = () => {
  const { BRAND, FLOW, PRODUCT } = getBrandProductFlowConfig()

  // Filter empty items for constructing router path overriding
  const routerPathArray = ['./brands', BRAND, FLOW, PRODUCT, 'router.tsx'].filter((item) => item)

  return routerPathArray.join('/')
}

export const getEnv = (): EnvName => {
  const hostName = window.location.hostname

  if (['localhost', '127.0.0.1'].includes(hostName)) {
    return EnvName.local
  } else if (hostName.includes('.dev.')) {
    return EnvName.dev
  } else if (hostName.includes('.acc.')) {
    return EnvName.acc
  } else {
    return EnvName.prod
  }
}

export const getPartnerHost = (partnerName: BrandName) => {
  const env = getEnv()

  return partnerHosts[partnerName]?.[env] || ''
}

export const getRedirectHost = (partnerName: BrandName, customEnv?: EnvName) => {
  const env = customEnv || getEnv()
  // Local environment should be redirected to the local web portal
  if (env === EnvName.local) {
    return 'http://localhost:8080/'
  }

  return partnerName ? partnerHosts[partnerName][env] : ''
}

const MISSING_MODULE_REGEXP = /Cannot find module/

export const getErrorType = (error: Error) =>
  error.message.search(MISSING_MODULE_REGEXP) > -1 ? ERROR_TYPES.missingModule : ERROR_TYPES.general

export const getSearchParams = (location: Location) => {
  return new Proxy(new URLSearchParams(location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }) as unknown as Record<string, string>
}

export type PartnerEnvSearchParams = { partner?: BrandName; env?: EnvName }

export const getPartnerNameFromURI = (location: Location): PartnerEnvSearchParams => {
  const { partner, env }: PartnerEnvSearchParams = getSearchParams(location)

  // Check if partnerName passed in URI is valid
  const isValidPartnerName = !!getPartnerHost(partner as BrandName)

  return isValidPartnerName ? { partner, env } : {}
}

// Help utility for material UI theme creation
export const getThemeOptions = ({ colors }: GetThemeOptionsArgType): ThemeOptions => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 321,
        md: 435,
        lg: 1025,
        xl: 1920,
      },
    },
    palette: {
      primary: {
        main: colors.main,
      },
    },
  }
}
